<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
      >
        <filter-promotores
          @applyFilters="applyFilters"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
        <v-simple-table>
          <thead>
            <tr>
              <th>Id Promotor</th>
              <th>Nome</th>
              <th>E-mail</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in model.promotores"
              :key="item.id"
            >
              <td>
                <v-chip
                  color="primary"
                  dark
                >
                  {{ item.id }}
                </v-chip>
              </td>
              <td>{{ item.nome }}</td>
              <td>{{ item.email }}</td>
              <td>
                <v-chip
                  color="primary"
                  :to="`/promotor/${item.id}/revendedores`"
                  dark
                >
                  Revendedores
                </v-chip>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="9"
      >
        <div class="text-center">
          <v-pagination
            v-model="params.page"
            :length="totalPaginas"
            circle
            @input="loadData"
          />
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="3"
      >
        <v-select
          v-model="params.perPage"
          :items="[10, 25, 50, 100]"
          label="Linhas por página"
          dense
          outlined
          @change="loadData"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import FilterPromotores from '../components/FilterPromotores.vue'

  export default {
    components: {
      FilterPromotores,
    },
    data: () => ({
      totalPaginas: 1,
      params: {
        page: 1,
        perPage: 10,
      },
      model: {
        promotores: [],
      },
    }),
    computed: {
      ...mapGetters(['user']),
    },
    mounted () {
      this.cleanUpModel()
    },
    methods: {
      cleanUpModel () {
        this.model.promotores = []
        this.params = {
          page: 1,
          perPage: 10,
        }
        this.loadData()
      },
      loadData () {
        this.$http.get('promotor/gestor', { params: this.params })
          .then(({ data }) => {
            this.totalPaginas = data.totalPaginas
            this.model.promotores = data.rows
          })
      },
      applyFilters (params) {
        Object.assign(this.params, params)
        this.params.page = 1
        this.loadData()
      },
    },
  }
</script>

<style>
a {
  text-decoration: none;
  font-weight: bold;
}
</style>
